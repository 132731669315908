@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.thank-you-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  min-height: 388px;
  box-sizing: border-box;
  color: $color-front;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 340px;
    width: 100%;
  }

  &__content-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__logo {
    max-width: 141px;
    max-height: 56px;
    margin-top: 96px;
  }

  &__title {
    margin-top: 16px;
    text-align: center;
    @include desktop-headline-medium-font;
  }

  &__main-text {
    @include desktop-title-font;
    text-align: center;
    margin-top: 40px;
  }

  &__action {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 44px;
  }

  &__buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    button {
      min-width: 184px;
    }
  }
}

@media only screen and (max-device-width: 420px) {
  .thank-you-template {
    &__content-center {
      flex: 1;
    }
  }
}

@media only screen and (max-device-width: 375px) {
  .thank-you-template {
    &__buttons {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      button {
        width: 100%;
      }
    }
  }
}

@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.TestInfo {
  box-sizing: border-box;
  max-width: 460px;
  min-width: 320px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  &__Content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 32px;
    color: $color-front;
    padding-bottom: 32px;

    &__Top {
      &__Main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        svg {
          height: 40px;
          width: 40px;
        }

        &__Title {
          margin-top: 20px;
          font-size: $title-font-size;
        }

        &__LocaleGuidance {
          margin-top: 28px;
        }
      }
    }

    &__Middle {
      display: flex;
      gap: 20px;
      width: 100%;
      justify-content: flex-end;

      .TestInfo__Content__Middle__Button {
        height: 44px;
        width: 100%;
      }
    }

    &__Bottom {
      display: flex;
      width: 100%;
      gap: 20px;

      .TestInfo__Content__Bottom__Notification {
        box-sizing: border-box;
        width: 100%;

        p {
          margin-top: -8px;
        }

        &__Link {
          padding: 0;
        }
      }
    }

    .TestInfo__Content__MobileDisclaimer__Notification {
      width: 100%;
      box-sizing: border-box;
    }
  }

  &__AdditionalItem {
    margin-left: auto;
    &:focus {
      outline: 2px solid black;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .TestInfo {
    display: flex;

    &__Content {
      flex: 1;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px 20px 20px;

      &__Top__NotesList {
        margin-top: 30px;
      }

      &__Middle {
        justify-content: center;
      }

      &__Bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        margin-bottom: 30px;
      }
    }
  }
}

@media only screen and (min-device-width: 480px) {
  .TestInfo {
    height: calc(100vh - 144px);

    &__Content {
      justify-content: center;

      &__Top__NotesList {
        margin-top: 40px;
      }
    }
  }
}

@media only screen and (min-height: 800px) {
  .TestInfo {
    &__Content {
      height: 100%;
    }
  }
}

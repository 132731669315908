@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  max-width: 380px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  .header {
    padding: 20px 20px 0 15px;
    width: 100vw;
    max-width: 960px;
    height: 72px;

    .backButton {
      color: $color-front;
      > * {
        font-weight: 400;
      }
      &:hover {
        path {
          fill: $color-interactive !important;
        }
      }
    }
    button {
      &:hover {
        background-color: transparent !important;
        color: $color-interactive;
        text-decoration: underline;
      }
    }

    path {
      fill: $color-front !important;
    }
  }

  .title {
    @include desktop-headline-font;
    margin-top: 30px;
    margin-bottom: 80px;
    text-align: center;
  }

  .testType {
    @include desktop-headline-medium-font;
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 32px;
    width: 100%;

    .textContainer {
      width: 100%;
    }

    .subTitle {
      @include desktop-title-medium-font;
      margin-bottom: 8px;
    }

    ul {
      padding-left: 24px;
    }

    li {
      @include desktop-body-font;
    }

    .description {
      @include desktop-body-font;
      margin-bottom: 8px;
    }

    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      button {
        width: 100%;
      }
    }
  }

  @media only screen and (min-device-width: 480px) {
    height: calc(100vh - 144px);
    justify-content: center;

    .header {
      position: absolute;
      top: 10px;
    }
  }

  /* Adjust padding on smaller screens */
  @media screen and (max-width: 380px) {
    padding: 0px 16px;
  }

  /* Wrap buttons to avoid overflow on smallest screens */
  @media screen and (max-width: 350px) {
    .buttonsContainer {
      flex-wrap: wrap;
    }
  }
}

@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.TestInfoNote {
  display: flex;
  margin-top: 32px;

  &__Icon {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    background-color: $color-disabled-back;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg {
      height: 24px;
      width: 24px;

      path {
        fill: $color-front;
      }
    }
  }

  &__TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-left: 19px;

    p {
      @include desktop-title-font;
      text-align: left;
      color: $color-front;
      min-height: 100%;
    }
  }

  &__TextAdjustment {
    margin-top: -2px;
  }
}

.TestInfoNote--Important {
  .TestInfoNote__Icon {
    background-color: $color-blue-back-dark;

    svg {
      color: $color-blue-front-light;

      path {
        fill: $color-blue-front-light;
      }
    }
  }

  .TestInfoNote__Text {
    font-weight: $medium-bold;
  }
}

@media screen and (min-width: 480px) {
  .TestInfoNote {
    &__TextItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }
  }
}

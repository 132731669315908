@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.role-specific-soft-skills-assessment {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 144px 20px;
  box-sizing: border-box;
  color: $color-front;

  &__content {
    width: 100%;
    max-width: 830px;
    margin: auto;
    flex: 1;
    flex-direction: column;
    display: flex;
  }

  .page-navbar {
    display: flex;
    justify-content: space-between;

    &__back-button {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    &__back-button-text {
      @include desktop-body-font;
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__note {
      @include desktop-body-font;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .sz-header-with-icon {
    margin-top: 8px;

    &__description {
      max-width: 380px;
      @include desktop-title-font;
    }
  }

  .assessment {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex: 1;
    margin-top: 40px;
    max-height: 500px;
  }

  .skills-list,
  .skills-level-selection {
    border: solid 1px $black-16;
    border-radius: 12px;
  }

  .skills-list {
    max-width: 310px;
    width: 310px;
    flex: 1;
    padding: 0 16px 24px;
    overflow: auto;

    &__header {
      position: absolute;
      background-color: $white;
      flex: 1;
      padding-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 36px;
      width: 278px;
    }

    &__header-title {
      @include desktop-body-medium-font;
    }

    &__mark-all-button {
      .button__content {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    &__mark-all-chart-icon {
      margin-top: -5px;
      width: 16px;
      height: 16px;
    }

    &__list-wrapper {
      display: flex;
      gap: 24px;
      flex-direction: column;
      margin-top: 68px;
    }

    &__previously-answered-skills,
    &__active-skills {
      display: flex;
      gap: 24px;
      flex-direction: column;
    }

    &__divider::after {
      height: 1px;
      display: block;
      content: ' ';
      background: repeating-linear-gradient(
        to right,
        $black-16 0,
        $black-16 6px,
        $white 6px,
        $white 12px
      );
    }

    &__loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 2px;
      background-color: $black-16;
    }
  }

  .skills-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      @include desktop-body-font;
      cursor: pointer;

      &_highlighted {
        color: $color-interactive;
      }
    }

    &__bar-chart {
      max-width: 20px;
      max-height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
  }

  .skills-level-selection {
    max-width: 504px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__main-content {
      padding: 16px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__header {
      @include desktop-body-medium-font;
    }

    &__loader-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__questionnaire {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
    }

    &__questionnaire-title {
      @include desktop-title-medium-font;
      margin-top: 24px;
    }

    &__questionnaire-description {
      @include desktop-body-font;
      color: $black-54;
      margin-top: 16px;
      height: 80px;
      width: 376px;
      text-align: center;
      overflow: auto;
    }

    &__questionnaire-note {
      margin-top: 24px;
      @include desktop-caption-font;
      height: 16px;
    }

    &__answer-options {
      display: flex;
      flex: 1;
      align-items: center;
      margin-bottom: 36px;

      &-option {
        width: 96px;
        height: 140px;
      }
    }

    &__actions {
      border-top: solid 1px $black-16;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      height: 57px;
      box-sizing: border-box;

      .button {
        &__content {
          display: flex;
          gap: 8px;
        }
        &:disabled {
          svg {
            margin-top: -1px;

            path {
              fill: $black-16;
            }
          }
        }
      }
    }

    &__previous-skill-button {
      .button__content {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 16px;
      }
      svg {
        transform: rotate(90deg);
      }
    }

    &__submit-button {
      svg {
        transform: rotate(90deg);

        path {
          fill: $white;
        }
      }
    }

    &__next-skill-button {
      .button__content {
        line-height: 16px;
      }
    }

    &__next-skill-button,
    &__submit-button {
      .button__content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        transform: rotate(-90deg);
      }
    }

    .skills-level-no-skills-left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &__completed-icon-wrapper {
        border: solid 4px rgba($green, 0.2);
        border-radius: 50%;
        padding: 12px;
      }

      &__completed-icon {
        width: 24px;
        height: 24px;

        path {
          fill: $green;
        }
      }

      &__text {
        @include desktop-body-medium-font;
        margin-top: 24px;
      }
    }
  }
}

.role-specific-soft-skills-assessment-mobile {
  padding: 20px 20px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: $color-front;

  .page-navbar {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__back-link {
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .sz-header-with-icon {
    margin-top: 48px;

    &__description {
      max-width: 380px;
    }
  }

  .assessment {
    margin-top: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__slider {
      flex: 1;
      display: flex;
      align-items: stretch;

      // Makes slides of slider to have same height as parent
      .slick-track {
        height: 100%;

        .slick-slide {
          & > div {
            height: 100%;
          }
        }
      }
    }

    &__slider-loader-wrapper {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }

  .assessment-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 32px;

    &__left {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &__progress-indicator {
      display: flex;
      align-items: center;
    }

    &__progress-indicator-focused {
      display: flex;
      background-color: rgba(0, 0, 0, 0.04);
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      @include desktop-body-medium-font;
    }

    &__skills-number {
      @include desktop-body-font;
    }

    &__mode-navigation-button {
      .button__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        svg {
          height: 16px;
          min-width: 16px;
          max-width: 16px;
          margin-top: -2px;

          path {
            fill: $blue-80;
          }
        }
      }
    }
  }

  .skills-level-questionnaire {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    &__heading {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__title {
      @include desktop-title-medium-font;
    }

    &__description {
      @include desktop-body-font;
      margin-top: 24px;
      height: 80px;
      overflow: auto;
      text-align: center;
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &__note {
      @include desktop-body-font;
      color: $grey;
      height: 20px;
    }

    &__answer-options {
      width: 100%;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      &-option {
        width: 96px;
        height: 140px;
      }
    }

    &__actions {
      margin-top: 16px;
      height: 44px;
      width: 100%;
    }

    &__actions-button {
      width: 100%;
    }
  }

  .skill-level-option {
    height: 94px;

    &__text {
      margin-top: 8px;
    }
  }
}

.sz-modal.skills-list-modal {
  border-radius: 0;
  padding: 0;
}

.skills-list-modal {
  width: 100%;

  .page-navbar {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__back-link {
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  &__content {
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include desktop-headline-font;
    text-align: center;
    margin-top: 48px;
  }

  &__list-wrapper {
    display: flex;
    gap: 24px;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 24px;
    overflow: auto;
    flex: 1;
    overflow: auto;
  }

  &__divider::after {
    height: 1px;
    display: block;
    content: ' ';
    background: repeating-linear-gradient(
      to right,
      $black-16 0,
      $black-16 6px,
      $white 6px,
      $white 12px
    );
  }

  &__previously-answered-skills,
  &__active-skills {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    width: 100%;
    gap: 20px;
  }

  &__actions-button {
    flex: 1;

    .button__content {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__mark-all-chart-icon {
    margin-top: -5px;
    width: 16px;
    height: 16px;
  }

  .skills-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      @include desktop-title-font;

      &--highlighted {
        color: $color-interactive;
      }
    }

    &__bar-chart {
      margin-top: -4px;
      width: 24px;
      height: 24px;
    }
  }
}
